import { render, staticRenderFns } from "./FolderCreate.vue?vue&type=template&id=699cab48&scoped=true&"
import script from "./FolderCreate.vue?vue&type=script&lang=js&"
export * from "./FolderCreate.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "699cab48",
  null
  
)

export default component.exports