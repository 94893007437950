<template>
	<div class="container-fluid">
		<div class="row">
			<div class="col-sm-10 offset-1">
				<div class="card pt-1">
					<div class="card-body">
						<DriveSearch @search="onSearchDrive"></DriveSearch>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import DriveSearch from "../../components/drive/DriveSearch.vue";

export default {
	name: "DriveShow",
	components: {DriveSearch},
	data() {
		return {
			loading: false,
		}
	},
	methods: {


		onSearchDrive(driveAddress) {
			this.loading = true;

			this.$store.state.shadow.show(driveAddress).then((resp) => {
				console.log("ShowDrives::", resp);

				if (!this.drives.filter((d) => d.publicKey.toString() === driveAddress).length) {
					console.log("Adding drive to array")
					this.drives.push({account: resp, publicKey: this.$store.state.shadow.toPublicKey(driveAddress)});
				}

				this.onVisitDrive(driveAddress)
			}).catch((err) => {
				console.log("Drive Show error", err);
				this.$toastr.e("Unable to load drive");
			}).finally(() => {
				this.loading = false;
			});
		},

		onVisitDrive(drive) {
			console.log("Setting active drive", drive);
			this.reset();
			this.activeDrive = drive
			this.uploadFiles = [];
			this.files = {};

			if (this.structure !== null)
				this.structure.reset();

			this.indexFiles();
		},
	}
}
</script>

<style scoped>

</style>